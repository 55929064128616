@import "~bootstrap/scss/_functions.scss";
@import "~bootstrap/scss/_variables.scss";
@import "~bootstrap/scss/mixins/_breakpoints.scss";
@import '~bootstrap/scss/_mixins.scss';


$COLOR_PRIMARY_BG: #476A6F;
$COLOR_ACCENT: #519E8A;
$COLOR_SECONDAY_BG: #7EB09B;
$COLOR_LIGHT_BG: #F6F7F8;
$COLOR_SHADE: rgba(71, 106, 111, 0.6);
$COLOR_LINK: green;

$COLOR_ERROR: red;

$COLOR_INPUT_BG: white;
$COLOR_CONTEN_BG: white;

$COLOR_TEXT_ONDARK: $COLOR_LIGHT_BG;
$COLOR_TEXT_ONLIGHT: $COLOR_PRIMARY_BG;

$MARGIN_SM: 0.5em;
$MARGIN_MD: 1.5em;
$MARGIN_LG: 3em;

$BORDER_SIZE: 0.5em;
$BORDER_RADIUS: 0.5em;

.hide-on-lg {
    display: block;
    @include media-breakpoint-up(lg) {
        display: none;
    }
}

.show-on-lg {
    display: none;
    @include media-breakpoint-up(lg) {
        display: block;
    }
}

@mixin input-style {
    border: 1px solid $COLOR_ACCENT;
    border-radius: $BORDER_RADIUS;
    &:focus {
        outline: none;
    }
    background: $COLOR_INPUT_BG;
    width: 100%;
    color: $COLOR_TEXT_ONLIGHT;
}

.error-msg {
    color: $COLOR_ERROR;
    font-size: small;
}

.green-link, a {
    color: $COLOR_LINK;
    &:hover {
        color: $COLOR_LINK;
    }
}

.white-link {
    color: white;
    &:hover {
        color: white;
        text-decoration: underline;
    }
}

.zstack {
	display: grid;
	align-items: center;
	justify-items: center;
}
.zstack > * {
	grid-area: 1/1/1/1;
}

// https://projects.lukehaas.me/css-loaders/
.loader,
.loader:before,
.loader:after {
  border-radius: 50%;
}
.loader {
  color: #509e89;
  font-size: 11px;
  text-indent: -99999em;
  margin: 55px auto;
  position: relative;
  width: 10em;
  height: 10em;
  box-shadow: inset 0 0 0 1em;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}
.loader:before,
.loader:after {
  position: absolute;
  content: '';
}
.loader:before {
  width: 5.2em;
  height: 10.2em;
  background: #f5f7f7;
  border-radius: 10.2em 0 0 10.2em;
  top: -0.1em;
  left: -0.1em;
  -webkit-transform-origin: 5.1em 5.1em;
  transform-origin: 5.1em 5.1em;
  -webkit-animation: load2 2s infinite ease 1.5s;
  animation: load2 2s infinite ease 1.5s;
}
.loader:after {
  width: 5.2em;
  height: 10.2em;
  background: #f5f7f7;
  border-radius: 0 10.2em 10.2em 0;
  top: -0.1em;
  left: 4.9em;
  -webkit-transform-origin: 0.1em 5.1em;
  transform-origin: 0.1em 5.1em;
  -webkit-animation: load2 2s infinite ease;
  animation: load2 2s infinite ease;
}
@-webkit-keyframes load2 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load2 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
