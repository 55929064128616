@import '../shared.scss';

.AutocompletedInput  {    
    padding-top: 2px;

    input {
        background-color: $COLOR_INPUT_BG;
        border: 1px solid $COLOR_ACCENT;
        border-left: none;
        border-radius: 0 $BORDER_RADIUS $BORDER_RADIUS 0;
        color: $COLOR_TEXT_ONLIGHT;
        box-shadow: none;
        transition: none;
        &:focus {
            background-color: $COLOR_INPUT_BG;
            border: 1px solid $COLOR_ACCENT;
            border-left: none;
            box-shadow: none;
            color: $COLOR_TEXT_ONLIGHT;
            transition: none;
        }
    }
    
    .input-group-text {
        background-color: transparent;
        color: $COLOR_PRIMARY_BG;
        border: none;
        border-radius: $BORDER_RADIUS;
        transition: none;
    }

    .input-group {

        .input-group-text {
            background-color: $COLOR_INPUT_BG;
            color: $COLOR_PRIMARY_BG;
            border: 1px solid $COLOR_ACCENT;
            border-right: none;
            border-radius: $BORDER_RADIUS 0 0 $BORDER_RADIUS;
            transition: none;
        }
        
    }

    .SuggestionBox {
        position: absolute;
        width: 100%;
        border-radius: 0 0 $MARGIN_SM $MARGIN_SM;
        border: 1px solid $COLOR_ACCENT;
        border-top: none;
        display: none;
    }

    &.active {
        .input-group{
            input {
                border-radius: 0 $BORDER_RADIUS 0 0;
                border-bottom-color: $COLOR_INPUT_BG;
            }

            .input-group-text {
                border-radius: $BORDER_RADIUS 0 0 0;
                border-bottom-color: $COLOR_INPUT_BG;
            }
        }
        .SuggestionBox {
            display: inherit;
        }
    }
}