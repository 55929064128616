@import '../shared.scss';

.Button {
    background-color: $COLOR_ACCENT;
    color: $COLOR_TEXT_ONDARK;
    &:hover {
        background-color: $COLOR_SECONDAY_BG;
    }
    border-radius: 0;
    text-transform: uppercase;
}

.StarsComp {
    color: $COLOR_ACCENT;

    &.selectable:hover {
        cursor: pointer;
    }
}

.InfoAgenzia {
    iframe {
        border: 4px solid$COLOR_CONTEN_BG;
        border-radius: .5em;
    }
}

.Input  {    
    &.error {
        input, input:focus {
            border: 1px solid $COLOR_ERROR;
            border-left: none;
        }
        .input-group {
            .input-group-text {
                border: 1px solid $COLOR_ERROR;
                border-right: none;
            }
        }
    }

    input {
        background-color: $COLOR_INPUT_BG;
        border: 1px solid $COLOR_ACCENT;
        border-left: none;
        border-radius: 0 $BORDER_RADIUS $BORDER_RADIUS 0;
        color: $COLOR_TEXT_ONLIGHT;
        box-shadow: none;
        transition: none;
        &:focus {
            background-color: $COLOR_INPUT_BG;
            border: 1px solid $COLOR_ACCENT;
            border-left: none;
            box-shadow: none;
            color: $COLOR_TEXT_ONLIGHT;
            transition: none;
        }
    }
    
    .input-group-text {
        background-color: transparent;
        color: $COLOR_PRIMARY_BG;
        border: none;
        border-radius: $BORDER_RADIUS;
        transition: none;
    }

    .input-group {
        .input-group-text {
            background-color: $COLOR_INPUT_BG;
            color: $COLOR_PRIMARY_BG;
            border: 1px solid $COLOR_ACCENT;
            border-right: none;
            border-radius: $BORDER_RADIUS 0 0 $BORDER_RADIUS;
            transition: none;
        }
        
    }

    &.active {
        .input-group{
            input {
                border-radius: 0 $BORDER_RADIUS 0 0;
                border-bottom-color: $COLOR_INPUT_BG;
            }

            .input-group-text {
                border-radius: $BORDER_RADIUS 0 0 0;
                border-bottom-color: $COLOR_INPUT_BG;
            }
        }
    }

    .foot-msg {
        font-size: small;
        text-align: right;
    }
}

.InfoBox {
    color: $COLOR_TEXT_ONLIGHT;
    
    .FloatingBox {


        background-color: $COLOR_LIGHT_BG;
        border: $MARGIN_SM solid $COLOR_SECONDAY_BG;
        .Title {
            background: $COLOR_SECONDAY_BG;
            color: $COLOR_TEXT_ONDARK;
            padding: $MARGIN_SM $MARGIN_MD;
        }

        .Content {
            @include media-breakpoint-down(lg) {
                padding: $MARGIN_SM $MARGIN_SM;
            }
            @include media-breakpoint-up(lg) {
                padding: $MARGIN_MD $MARGIN_LG;
            }
            
            .Input {
                margin-top: $MARGIN_SM;
            }
        }
    }
}

.MyCheckbox {
    .MuiCheckbox-root {
        color: $COLOR_ACCENT;
    }
    &.error { .MuiCheckbox-root {
        color: $COLOR_ERROR;
    }}

    a {
        color: $COLOR_TEXT_ONLIGHT;
        font-style: italic;
    }
}

.MySelect {
    
    &.error { .MuiSelect-root {
        border-color: $COLOR_ERROR;
    }}

    .MuiSelect-root {
        @include input-style;
     }

     .MuiFilledInput-underline {
        &::before, &::after{
            border: none;
            outline: none;
        }
    }

    label, .MuiFormLabel-root.Mui-focused {
        color: $COLOR_TEXT_ONLIGHT;
        padding-right: $MARGIN_MD;
    }
}

.MessageBox{
    background-color: $COLOR_INPUT_BG;
    border-radius: $BORDER_RADIUS;
    margin: $MARGIN_MD 0 0 0;
    padding: $MARGIN_SM $MARGIN_MD;
    text-align: center;
}