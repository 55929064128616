@import '../../shared.scss';

.TopBar {
    .container{
        display: flex;
    }
    
    background-color: $COLOR_PRIMARY_BG;
    padding: $MARGIN_SM 0;
    a {
        color: $COLOR_TEXT_ONDARK;
        &:hover {
            text-decoration: none;
        }
    }

    .displayName {
        @include media-breakpoint-down(sm) {
            display: none;
        } 
    }
}

.SearchBand {
    background-color: $COLOR_SECONDAY_BG;
    padding: $MARGIN_SM 0;
}

.main {
    background-color: $COLOR_LIGHT_BG;
    color: $COLOR_TEXT_ONLIGHT;
    padding: $MARGIN_MD 0;
    @include media-breakpoint-up(lg) {
        padding: $MARGIN_LG 0;
    }
}

.vertical-fill {flex-grow: 1;}

.Footer {
    background-color: $COLOR_PRIMARY_BG;
    padding: $MARGIN_MD 0;
    border-top: $BORDER_SIZE solid $COLOR_SECONDAY_BG;

    a {
        color: white;
    }
}

.Reviews {
    @include media-breakpoint-up(lg) {
        padding-top: $MARGIN_LG;
    }

    .Review {
        background-color: $COLOR_CONTEN_BG;
        border-radius: $BORDER_RADIUS;
        padding: $MARGIN_MD;
        padding-bottom: $MARGIN_SM;
        margin: 0 0 $MARGIN_MD 0;

        .footer {
            text-align: right; 
            margin-bottom: 0;
            font-size: smaller;
        }
    }

    .StarsComp {
        color: $COLOR_ACCENT !important;
    }
}

// only way to remove :-moz-focusring from select item
* {
    outline: none !important;
}

.NewReview {
    textarea {        
        @include input-style;
        padding: $MARGIN_SM;
        resize: none;
    }

    .MuiInputBase-root, .MuiFormControl-root {
        width: 100%;
        background: none;
        border: none;
        outline: none;
        border-radius: $BORDER_RADIUS;
    }
    
    .MuiFormControl-root {
        margin-top: 1em;
    }

    .error-msg {
        display:none;
    }

    .error {

        textarea {
            border-color: $COLOR_ERROR;
        }
        .error-msg {
            display: inline-block;
            color: $COLOR_ERROR;
            font-size: small;
        }

        .StarsComp {
            border: 1px dashed $COLOR_ERROR;
        }
    }

    .Input {
        .input-group-prepend {
            display: none;
        }

        input {
            padding: $MARGIN_SM;
            border-left: 1px solid $COLOR_ACCENT;
            border-radius: $BORDER_RADIUS !important;
            border-bottom-left-radius: 0 !important;
            border-bottom-right-radius: 0 !important;
            border-bottom: 1px dashed $COLOR_ACCENT;
        }
    }
}

.Login {
    .signinButton, .signupButton {
        // background-color: $COLOR_ACCENT;
        margin: 1px;
    }
}

.LoginSocial {
    .Button {
        margin: 5px 0px;
    }
}
.OnboardingBox {
    .FloatingBox{
        @include media-breakpoint-down(sm) {
            width: 95vw;
        }
        @include media-breakpoint-up(sm) {
            width: 500px
        }
    }

    .MuiFormControl-root {
        width: 100%;
        margin-bottom: $MARGIN_SM;
    }

    .Input { 
        margin-bottom: $MARGIN_MD;
    }
    
}

.NewAg {
    .Input { 
        margin-bottom: $MARGIN_SM;
    }

    .citta {
        input {
            border-right: 1px dashed $COLOR_ACCENT;
            border-bottom-right-radius: 0;
            border-top-right-radius: 0;
        }
    }

    .provincia {
        .input-group-prepend{
            display: none;
        }
        input {
            border-left: 0;
            border-bottom-left-radius: 0;
            border-top-left-radius: 0;
        }
    }
}